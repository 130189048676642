import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../styles/NavBar.css';

const NavBar = ({ toggleNav, navExpanded }) => {
  return (
    <Navbar expanded={navExpanded} expand="lg" variant="dark" className="navbar-custom">
      <Navbar.Brand href="/">Beirut Cafe</Navbar.Brand>
      <Navbar.Toggle onClick={toggleNav} aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto" onClick={toggleNav}>
            {/* <Nav.Link as={Link} to="/category/Food">Food</Nav.Link> */}
            <Nav.Link as={Link} to="/category/Drink">Drink</Nav.Link>
            <Nav.Link as={Link} to="/category/Sweet">Sweet</Nav.Link>
            <Nav.Link as={Link} to="/category/Bubbly">Bubbly</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
