import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProductsDisplay from './components/ProductsDisplay';
import ProductDetail from './components/ProductDetail';
import ProductUploadForm from './components/ProductUploadForm';
// import NavBar from './components/NavBar';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import Redirect from './components/Redirect'; // Import the Redirect component
import './App.css'; // Make sure this path is correct for your CSS file
import 'bootstrap/dist/css/bootstrap.min.css';
import ProductEditForm from './components/ProductEditForm';
// ... other imports

const App = () => {
  return (
    <BrowserRouter>
    {/* <NavBar /> */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/category/food" element={<ProductsDisplay />} /> */}
        <Route path="/category/:categoryName" element={<ProductsDisplay />} />
        <Route path="/upload" element={<ProductUploadForm />} />
        <Route path="/edit" element={<ProductEditForm />} />
        <Route path="/product/:productId" element={<ProductDetail />} />
        <Route path="/Facebook" element={<Redirect to="https://www.facebook.com/lastoria.hebron/" />} /> {/* Add this line */}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
