
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Import getStorage for Firebase Storage

const firebaseConfig = {
  apiKey: "AIzaSyDc8MoiNedB3s0xRjwLg8zh1A4df_M8smA",
  authDomain: "beirut-cafe-fb911.firebaseapp.com",
  projectId: "beirut-cafe-fb911",
  storageBucket: "beirut-cafe-fb911.appspot.com",
  messagingSenderId: "436441472189",
  appId: "1:436441472189:web:039400b38a7cc312e86bd4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Firestore instance
const firestore = getFirestore(app);

// Firebase Storage instance
const storage = getStorage(app);

export { firestore, storage }; // Export both Firestore and Storage
