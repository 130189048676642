import React, { useState, useEffect } from 'react';
import Rating from 'react-rating-stars-component';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import '../styles/RatingComponent.css';

const RatingComponent = ({ language }) => {
  const [currentRating, setCurrentRating] = useState(0);
  const [clientName, setClientName] = useState(''); // State for client's name
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const fetchRatingsAndComments = async () => {
      const ratingsCollection = collection(firestore, 'ratings');
      const querySnapshot = await getDocs(ratingsCollection);
      const commentsArray = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.comment && data.name) { // Check if the document includes a name and comment
          commentsArray.push(`${data.name}: ${data.comment}`); // Combine name and comment
        }
      });

      setComments(commentsArray);
    };

    fetchRatingsAndComments();
  }, []);

  const handleSubmit = async () => {
    if (currentRating > 0) {
      await addDoc(collection(firestore, 'ratings'), {
        name: clientName, // Save the client's name
        comment: comment, // Save the comment
        rating: currentRating // Save the rating
      });
      setClientName(''); // Clear client name field after submission
      setComment(''); // Clear comment field after submission
      setCurrentRating(0); // Reset rating after submission
      // Optionally, fetch comments again here to update the list
    } else {
      alert(language === 'En' ? 'Please select a rating' : 'الرجاء اختيار تقييم');
    }
  };

  return (
    <div className='rate-container'>
      <h3 className='rate-us'>{language === 'En' ? 'Rate Us' : 'قيمنا'}</h3>
      <div         classNames="rating">
      <Rating
        count={5}
        value={currentRating}
        size={24}
        activeColor="#ffd700"
        inactiveColor="#ddd"
        onChange={(rate) => setCurrentRating(rate)}
        required
        classNames="rating"
      />
      </div>
      <input
        type="text"
        placeholder={language === 'En' ? 'Your Name (optional)' : '(اختياري) اسمك'}
        value={clientName}
        onChange={(e) => setClientName(e.target.value)}
        className="client-name-input"
      />
      <textarea
        placeholder={language === 'En' ? 'Leave a comment (optional)' : 'اترك تعليقا (اختياري)'}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        className="comment-box"
      ></textarea>
      <div className="submit-btn-container">
  <button onClick={handleSubmit} className="submit-btn">
    {language === 'En' ? 'Submit' : 'إرسال'}
  </button>
</div>
    </div>
  );
};

export default RatingComponent;
