import React from 'react';
import '../styles/Footer.css'; // Make sure the path to your CSS file is correct
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons'; // Import the phone icon

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container text-center">
        <span className="footer-logo">Beirut Cafe & Restaurant</span>
        <div className="footer-socials">
          {/* Existing social media icons */}
          <a href="https://www.facebook.com/beirut.cafe.restaurant/" aria-label="Facebook" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href="https://www.instagram.com/beirutcafe5/" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          {/* <a href="https://www.tiktok.com/@lastoria.restaura?is_from_webapp=1&sender_device=pc" aria-label="TikTok" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTiktok} />
          </a> */}
          {/* Call icon */}
          <a href="tel:" aria-label="Call Us">
            <FontAwesomeIcon icon={faPhone} />
          </a>
        </div>
        <div className="footer-copy">
          © 2024 All rights reserved. Done by <a href="https://growup-ps.com/social-media" target="_blank" rel="noopener noreferrer" className='growup'>Grow Up</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
