// No changes needed here, since responsiveness is handled by the parent container's classes
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/ProductCard.css';

const ProductCard = ({ product }) => {
  const { name_en, name_ar, description_en, description_ar, image, price } = product;

  return (
    <Link to={`/product/${product.id}`} className="text-decoration-none">
      <div className="card product-card">
      <img src={image} alt={name_en} className="card-img-top" />
      <div className="card-body">
        <h5 className="card-title">{name_en}</h5>
        <h5 className="card-title">{name_ar}</h5>
        {/* <p className="card-text">{description_en}</p>
        <p className="card-text">{description_ar}</p> */}
        <div className="price text-end">{price}₪</div>
      </div>
    </div>
    </Link>
  );
};

export default ProductCard;
