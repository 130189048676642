import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import { firestore } from '../firebaseConfig';
import '../styles/StarDisplay.css'; // Ensure this path is correct

const StarDisplay = ({ rating }) => {
  const [totalRating, setTotalRating] = useState(0);
  const [ratingCount, setRatingCount] = useState(0);

  const totalStars = 5;
  let stars = [];
  
  // Ensure rating is a number and handle edge cases
  const numericRating = parseFloat(rating) || 0;
  const roundedRating = Math.round(numericRating * 2) / 2; // Round to nearest half

  useEffect(() => {
    // Fetch existing ratings from Firestore
    const fetchRatings = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'ratings'));
      let total = 0;
      querySnapshot.forEach((doc) => {
        total += doc.data().rating;
      });
      setTotalRating(total / querySnapshot.size);
      setRatingCount(querySnapshot.size);
    };

    fetchRatings();
  }, []);


  for (let i = 1; i <= totalStars; i++) {
    if (i <= roundedRating) {
      stars.push(<FaStar key={i} className="star" />);
    } else if (i === Math.ceil(roundedRating) && !Number.isInteger(roundedRating)) {
      stars.push(<FaStarHalfAlt key={i} className="star" />);
    } else {
      stars.push(<FaRegStar key={i} className="star" />);
    }
  }

  return <div className="star-rating">{stars}
        <div className='rating-number'>
        {/* <p className='Rate-section'>{totalRating.toFixed(1)} ({ratingCount} <span className='Rate-section'>ratings </span>)</p> */}
        </div>
</div>;
};

export default StarDisplay;
