import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import RatingComponent from './RatingComponent';
import { firestore } from '../firebaseConfig';
import NavBar from './NavBar';
import '../styles/HomePage.css';
import StarDisplay from './StarDisplay'; // Make sure this is correctly imported
// import PopupComponent from './PopupComponent';
// import popupImage from '../images/ramadanPost.jpg';

const HomePage = () => {
  const [totalRating, setTotalRating] = useState(0);
  const [ratingCount, setRatingCount] = useState(0);
  const [navExpanded, setNavExpanded] = useState(false);
  const [averageRating, setAverageRating] = useState(0);
  const [showRatingComponent, setShowRatingComponent] = useState(false);
  const [language, setLanguage] = useState('En'); // New state for managing language
  const [clientComments, setClientComments] = useState([]);
  const [visibleComments, setVisibleComments] = useState(5); 
  // const [showPopup, setShowPopup] = useState(true); // Controls the visibility of the popup

  // const closePopup = () => {
  //   setShowPopup(false); // This will hide the popup
  // };

  const toggleNav = () => {
    setNavExpanded(!navExpanded);
  };


  useEffect(() => {
    const fetchRatings = async () => {
      try {
        const ratingsCollection = collection(firestore, 'ratings'); // Accessing 'ratings' collection
        const response = await getDocs(ratingsCollection);
        let totalRating = 0;
        let count = 0;

        response.docs.forEach(doc => {
          const data = doc.data();
          if (data.rating) {
            totalRating += data.rating;
            count++;
          }
        });

        if (count > 0) {
          setAverageRating(totalRating / count); // Set the average rating
        }
      } catch (error) {
        console.error("Error fetching ratings:", error);
      }
    };

    fetchRatings();
  }, []);

useEffect(() => {
    // Fetch ratings and comments from Firestore
    const fetchRatingsAndComments = async () => {
      const ratingsCollection = collection(firestore, 'ratings');
      const querySnapshot = await getDocs(ratingsCollection);
      let total = 0;
      let commentsArray = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        total += data.rating;
        if (data.comment && data.name) {
          commentsArray.push({
            name: data.name,
            comment: data.comment,
            rating: data.rating
          });
        }
      });

      setTotalRating(total / querySnapshot.size);
      setRatingCount(querySnapshot.size); // Update rating count
      setClientComments(commentsArray); // Set comments
    };

    fetchRatingsAndComments();
  }, []);

  const handleSeeMoreClick = () => {
    setVisibleComments((prevVisibleComments) => prevVisibleComments + 5); // Increase the number of visible comments by 5
  };
  

  const handleAddRateClick = () => {
    setShowRatingComponent(true);
  };

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'En' ? 'Ar' : 'En'));
  };

  return (
    <div className="homepage page-background">
      <NavBar toggleNav={toggleNav} navExpanded={navExpanded} />
      <button onClick={toggleLanguage} className="language-toggle">
        {language === 'En' ? 'عربي' : 'English'}
      </button>
      <div className="hero-section">
        {/* Use language state to determine text */}
        <h1>{language === 'En' ? 'Welcome to Beirut Cafe & Restaurant' : 'مرحبا بكم في كافية و مطعم بيروت '}</h1>
        <p>{language === 'En' ? 'Beirut is a place where you can be at ease, with an atmosphere of comfort and refreshing nature. Beirut is a place where you work and meet your friends. Beirut is a peaceful place.' : 'بيروت هو المكان الذي تكون فيه براحتك، مع اجواء من الراحة و الطبيعة المنعشة، بيروت مكان تعمل به، تلتقي باصدقائك. بيروت مكان بريح البال'}</p>
        <button className='showthemenu' onClick={toggleNav}><h4>{language === 'En' ? 'Show The Menu' : 'عرض القائمة'}</h4></button>
      </div>
      <div className="container my-5">
        <section className="rating-section">
          <h3>{language === 'En' ? 'Average Rating' : 'التقييم العام'}</h3>
          <StarDisplay rating={averageRating} />
          <p className='Rate-section'>{totalRating.toFixed(1)} ({ratingCount} <span className='Rate-section'>{language === 'En' ? 'ratings' : 'تقييمات'}</span>)</p>
          <button className='addrate' onClick={handleAddRateClick}><h4>{language === 'En' ? 'Add Rate' : 'أضف تقييم'}</h4></button>
          {showRatingComponent && <RatingComponent language={language} />}
          <h4 className="client-comments-header">{language === 'En' ? 'What our Clients Say' : 'ما يقوله عملاؤنا'}</h4>
          <div className="client-comments">
          {clientComments.slice(0, visibleComments).map((item, index) => (
    <div key={index} className="client-comment">
      <div className="comment-header">
        <strong>{item.name}</strong>
        <StarDisplay rating={item.rating} size={20} />
      </div>
      <p>{item.comment}</p>
    </div>
  ))}
</div>
      {visibleComments < clientComments.length && ( // Only show the "See More" button if there are more comments to display
        <button onClick={handleSeeMoreClick} className="see-more-btn">
          {language === 'En' ? 'See More' : 'رؤية المزيد'}
        </button>
      )}
        </section>
      </div>
      {/* {showPopup && (
        <PopupComponent
          onClose={closePopup}
          imageUrl={popupImage} // Replace with your actual image path
        />
      )} */}
    </div>
  );
};

export default HomePage;